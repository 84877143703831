import DefaultFormat from './default_format.helper'
import { strToTime } from '../style_string.helper'

export default class PaulinaZarrabeFormat extends DefaultFormat {
  formatGuardianCSV () {
    const { guardian } = this.jsonObj
    const g = guardian?.toLowerCase().trim()
    if (g === undefined || g === '' || g === null || g === 'no') return 'No'
    return 'Si'
  }

  formatLab () {
    const { lab } = this.jsonObj
    const l = lab?.toLowerCase().trim()
    if (l === undefined || l === '' || l === null || l === 'no' || l === 'n') return false
    return true
  }

  formatTimeCSV () {
    const { time } = this.jsonObj
    if (time === undefined) throw new Error('No se encontró el campo de hora.')
    if (time === '') return ''
    if (time.includes('AM') || time.includes('PM')) return time // Time is already formatted
    const formatTime = strToTime(time)
    const ampm = formatTime.split(' ')[1]
    const hour = parseInt(formatTime.split(':')[0])
    if (!(hour === 12 && ampm === 'PM') &&
      ((hour === 12 && ampm === 'AM') ||
      (hour < 6 && ampm === 'AM') ||
      (hour >= 8 && ampm === 'PM'))
    ) {
      throw new Error('La hora de la cita esta fuera de horario')
    }
    return formatTime
  }
}
