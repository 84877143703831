import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import { getDateRange } from '../../../helpers/style_string.helper'
import 'react-datepicker/dist/react-datepicker.css'

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css'
registerLocale('es', es)

function RangeDatePicker ({ dateRange, setDateRange, setDates, loadingData }) {
  const [startDate, endDate] = dateRange
  return (
    <DatePicker
      selectsRange
      dateFormat='dd/MM/yyyy'
      className='form-control date-picker'
      startDate={startDate}
      endDate={endDate}
      onChange={(date) => {
        setDateRange(date)
        const [start, end] = date
        if (!start || !end) return
        setDates(getDateRange(start, end))
        loadingData.current = false
      }}
      onInputClick={() => {
        setDateRange(([null, null]))
      }}
      locale='es'
    />
  )
}

export default RangeDatePicker
